function InterfacePagination() {
  this.page = 1
  this.pages = 1
  this.limit = 10
  this.length = 1
  this.totalVisible = 1
  this.totalRecord = 1
  this.sortBy = ''
}

// function InterfacePaginationFromBackend() {
//   this.current_page = 0
//   this.limit = 0
//   this.total_page = 0
//   this.total_record = 0
// }

function paginationDefault() {
  const pagiantion = new InterfacePagination()
  return pagiantion
}

function paginationMapper(p) {
  const pagination = new InterfacePagination()
  pagination.page = p.current_page
  pagination.limit = p.limit
  pagination.pages = p.total_page
  pagination.totalRecord = p.total_record
  pagination.length = (p.total_page > 10) ? p.total_page : p.total_page
  pagination.totalVisible = 10
  pagination.sortBy = ''
  return pagination
}

export default {
  paginationDefault,
  paginationMapper
}
